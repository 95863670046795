import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Row, Col } from "react-bootstrap"

import '../../scss/pages/artist.scss'

const Page = ({ data: { prismicArtist } }) => {
  const { lang, data } = prismicArtist
  return (
    <Layout lang={lang}>
      <SEO title={data.name.text} />
      <div className="artist-heading">
        <Container>
          <h1 className="gls-title">{data.name.text}</h1>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md="12">
            <div dangerouslySetInnerHTML={{ __html: data.description.html }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ArtistBySlug($uid: String!, $lang: String!) {
    prismicArtist(uid: {eq: $uid}, lang: {eq: $lang}) {
      uid
      lang
      data {
        name {
          text
        }
        description {
          html
        }
        wiki_link {
          url
          target
        }
      }
    }
  }
`
